import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 30px 15px 80px;
  width: 100%;
  max-width: 950px;
`;

export { PaginationWrapper };
