import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ArticleHeader from '../components/articles/ArticleHeader';
import ContactCaseStudy from '../components/ContactCaseStudy';
import Layout from '../components/layout/layout';
import MoreArticles from '../components/articles/MoreArticles';
import PaginationPost from '../components/pagination/PaginationPost';
import SEO from '../components/seo';

import { Article } from '../styles/article';

const Hero = styled.div`
  min-height: 350px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
  @media (min-width: 768px) {
    min-height: 650px;
  }
  
  @media (min-width: 1920px) {
    min-height: 850px;
  }
`;

const BlogPost = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { next, previous, type } = pageContext;
  const {
    title,
    excerpt,
    featured_image: featuredImage,
    preview_image: previewImage,
  } = frontmatter;
  const featured = featuredImage || previewImage;
  const showLabel = ['blog', 'case-studies'].includes(type);

  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <ArticleHeader {...frontmatter} showLabel={showLabel} />
      { featured && (<Hero image={featured.publicURL} />) }
      <Article dangerouslySetInnerHTML={{ __html: html }} />
      <PaginationPost prevHref={previous.slug} nextHref={next.slug} />
      <ContactCaseStudy />
      <MoreArticles text="Check our latest case studies" />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        excerpt
        preview_image {
          publicURL
        }
        featured_image {
          publicURL
        }
      }
      html
    }
  }
`;
