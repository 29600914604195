import React from 'react';
import styled, { css } from 'styled-components';

import { WithUnderline } from '../../styles/link';
import { color } from '../../styles/theme';

import arrow from '../../images/arrow.svg';


const Link = styled(WithUnderline)`
  position: relative;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  color: ${color('turquoise-blue')};
  text-decoration: none;
  
  @media (min-width: 768px) {
    font-size: 24px;
  }
  
  img {
    position: relative;
    top: .125em;
    margin-left: 10px;
    height: 1em;
    width: 1em;
    transition: all .2s ease-in-out;
    ${(p) => p.direction === 'prev' && css`
      margin-left: 0;
      margin-right: 10px;
      transform: rotateY(180deg);
    `}
  }
`;

const PaginateLink = ({ text, href, direction }) => (
  <Link to={href} direction={direction}>
    { direction === 'prev' ? (
      <>
        <img src={arrow} alt="" />
        <span>{ text }</span>
      </>
    ) : (
      <>
        <span>{ text }</span>
        <img src={arrow} alt="" />
      </>
    ) }
  </Link>
);

export { PaginateLink };
