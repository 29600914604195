import React from 'react';
import { PaginateLink } from './PaginateLink';
import { PaginationWrapper } from '../../styles/pagination';


const PaginationPost = ({ prevHref, nextHref }) => {
  if (!prevHref && !nextHref) return null;

  return (
    <PaginationWrapper css={{ maxWidth: '730px' }}>
      { prevHref && (
        <PaginateLink href={prevHref} text="Previous" direction="prev" />
      ) }
      { nextHref && (
        <PaginateLink href={nextHref} text="Next" direction="next" />
      ) }
    </PaginationWrapper>
  );
};

export default PaginationPost;
